'use client';
import axios from 'axios';
import { useEffect, useState, lazy } from 'react';
import Link from 'next/link';
import useLocalStorage from '@/components/Hooks/UseLocalStorage';
import Recommended from '@/components/Carousels/Recommended';
import MultiCarousel from '@/components/Carousels/MultiCarousel';
import TopBrands from '../NotLoggedIn/TopBrands';
import classes from './RecentItems.module.css';
import PlaceHolder from '@/components/Carousels/PlaceHolder';
import LineSeparator from '@/components/UI/LineSeparator';

const RecentItems = () => {
	const [activeListings, setActiveListings] = useState([]);
	const [loading, setLoading] = useState(false);
	const [recentlyViewed, setRecentlyViewed] = useLocalStorage(
		'recentlyViewed',
		[]
	);

	const getListingsHandler = async (ids) => {
		setLoading(true);
		await axios
			.post(
				`${process.env.NEXT_PUBLIC_SERVER_API}/api/other/listing-with-ids`,
				{
					ids: ids,
					status: 'active',
				}
			)
			.then((res) => {
				// Reorder listings to match the order of the ids array
				const orderedActiveListings = ids.map((id) =>
					res.data.find((listing) => listing._id === id)
				);
				setActiveListings(orderedActiveListings);
			})
			.catch((err) => {
				console.log(err);
			});

		setLoading(false);
	};

	useEffect(() => {
		let ids = [];
		for (let r of recentlyViewed) {
			ids.push(r._id);
		}

		// Make sure to reverse the ids here to match the order you desire
		if (ids.length > 0) {
			getListingsHandler([...ids].reverse()); // Creates a reversed copy of ids
		}
	}, [recentlyViewed]);

	return (
		<>
			<div className={classes.main}>
				<TopBrands />
				{activeListings?.length > 0 || loading ? (
					<>
						{' '}
						<div className={classes.headerBox}>
							<h6 className={classes.header}>Recently Viewed</h6>
							<Link
								className={classes.Link}
								href={'/favorites?tab=recently-viewed'}
							>
								<h6 className={classes.seeAll}>See all</h6>
							</Link>
						</div>
						{loading ? (
							<PlaceHolder />
						) : (
							<MultiCarousel listings={activeListings} />
						)}
						<LineSeparator />
					</>
				) : (
					''
				)}
				{activeListings?.length > 0 || loading ? (
					<>
						<div className={classes.headerBox}>
							<h6 className={classes.header1}>Recommended for you</h6>
							<Link className={classes.Link} href={'/collections?sort=6'}>
								<h6 className={classes.seeAll}>See all</h6>
							</Link>
						</div>
						{loading ? (
							<PlaceHolder />
						) : (
							<div className={classes.alignCenter}>
								<Recommended recentlyViewed={activeListings} long={true} />
							</div>
						)}
					</>
				) : (
					''
				)}
			</div>
		</>
	);
};

export default RecentItems;
