'use client';
import { useState } from 'react';
import Explore from './Explore';
import NewArrivals from './NewArrivals';
import RecentItems from './RecentItems';
import classes from './Choice.module.css';

const Choice = () => {
	const [choice, setChoice] = useState(0);
	return (
		<>
			<div className={classes.main}>
				<div className={classes.choices}>
					<div
						onClick={() => setChoice(0)}
						className={choice === 0 ? classes.activeChoice : classes.choice}
					>
						For You
					</div>
					<div
						onClick={() => setChoice(1)}
						className={choice === 1 ? classes.activeChoice : classes.choice}
					>
						Explore
					</div>
					<div
						onClick={() => setChoice(2)}
						className={choice === 2 ? classes.activeChoice : classes.choice}
					>
						New Arrivals
					</div>
				</div>

				{choice === 0 && (
					<>
						<RecentItems />
					</>
				)}
				{choice === 1 && (
					<>
						<Explore />
					</>
				)}
				{choice === 2 && (
					<>
						<NewArrivals />
					</>
				)}
			</div>
		</>
	);
};

export default Choice;
