'use client';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import { useRouter } from 'next/navigation';
import { useSalesContext } from '@/context/sales';

import classes from './Header.module.css';
import { useAuthContext } from '@/context';

const Header = () => {
	const { auth } = useAuthContext();

	const router = useRouter();

	const [needAttention, setNeedAttention] = useState({
		pickup: { number: 0, palletizing: 0 },
		approve: { number: 0, value: 0 },
		offers: { number: 0, value: 0 },
		deliver: { number: 0 },
	});

	const { sales } = useSalesContext();

	useEffect(() => {
		let req = { value: 0, number: 0 };
		let off = { value: 0, number: 0 };
		let del = { number: 0 };
		let pick = { number: 0, palletizing: 0 };

		if (
			!sales.openSales?.loading &&
			sales.openSales?.loaded &&
			sales?.openSales?.orders?.length > 0
		) {
			for (let order of sales.openSales.orders) {
				if (order.status === 'requested' && order.breakdown?.totalAfterTax) {
					req = {
						value: req.value + order.breakdown.costOfItems,
						number: req.number + 1,
					};
				} else if (order.approved) {
					if (order.type === 'delivery' && order.deliveryType === 'seller') {
						if (moment(order.exactDeliveryDate).isSameOrBefore(moment())) {
							del = {
								number: del.number + 1,
							};
						}
					} else {
						if (
							moment(order.exactPickupDate).isSameOrBefore(moment()) ||
							order.type === 'pickup'
						) {
							pick = {
								palletizing:
									pick.palletizing + order.deliveryType === 'fcDelivery'
										? 1
										: 0,
								number: pick.number + 1,
							};
						}
					}
				}
			}
		}

		if (
			!sales.openOffers?.loading &&
			sales.openOffers?.loaded &&
			sales?.openOffers?.orders?.length > 0
		) {
			for (let order of sales.openOffers.orders) {
				if (order.status === 'offer-sent') {
					off = {
						value: off.value + order.breakdown.costOfItems,
						number: off.number + 1,
					};
				}
			}
		}

		setNeedAttention({
			pickup: pick,
			offers: off,
			approve: req,
			deliver: del,
		});
	}, [sales]);

	const handleGoToSales = () => {
		router.push(`/sales?&tab=sales&selection=openSales`);
	};

	const handleGoToOffers = () => {
		router.push(`/sales?&tab=sales&selection=openOffers`);
	};

	return (
		<>
			{auth.isAuthenticated ? (
				<div className={classes.header}>
					Welcome back, <b>{auth.user.firstName}!</b>
					{sales.openSales?.orders?.length > 0 && (
						<>
							<div className={classes.needAttentionRow}>
								{needAttention.approve.number > 0 && (
									<div
										className={classes.needAttentionDiv}
										onClick={handleGoToSales}
									>
										<h3 className={classes.attentionTitle}>Approve sales</h3>

										<div className={classes.attentionNumber}>
											{needAttention.approve.number}
										</div>
										<div className={classes.attentionFooter}>
											<b>
												$
												{needAttention.approve.value.toLocaleString(undefined, {
													maximumFractionDigits: 0,
												})}
											</b>{' '}
											total value
										</div>
										<div className={classes.attentionMark}>
											<AiOutlineEye className={classes.icon} />{' '}
										</div>
									</div>
								)}
								{needAttention.offers.number > 0 && (
									<div
										className={classes.needAttentionDiv}
										onClick={handleGoToOffers}
									>
										<h3 className={classes.attentionTitle}>Check offers</h3>

										<div className={classes.attentionNumber}>
											{needAttention.offers.number}
										</div>
										<div className={classes.attentionFooter}>
											<b>
												$
												{needAttention.offers.value.toLocaleString(undefined, {
													maximumFractionDigits: 0,
												})}
											</b>{' '}
											total value
										</div>
										<div className={classes.attentionMark}>
											<AiOutlineEye className={classes.icon} />{' '}
										</div>
									</div>
								)}

								{needAttention.pickup.number > 0 && (
									<div
										className={classes.needAttentionDiv}
										onClick={handleGoToSales}
									>
										<h3 className={classes.attentionTitle}>
											Being picked up today
										</h3>
										{/* <p className={classes.subHeader}>or past days</p> */}
										<div className={classes.attentionNumber}>
											{needAttention.pickup.number}
										</div>
										<div className={classes.attentionFooter}>
											{needAttention.pickup.palletizing > 0 && (
												<div className={classes.subHeader}>
													{needAttention.deliver.palletizing} need palletizing
												</div>
											)}
										</div>
										<div className={classes.attentionMark}>
											<AiOutlineEye className={classes.icon} />{' '}
										</div>
									</div>
								)}

								{needAttention.deliver.number > 0 && (
									<div
										className={classes.needAttentionDiv}
										onClick={handleGoToSales}
									>
										<h3 className={classes.attentionTitle}>Deliver today</h3>
										<div className={classes.subHeader}>or past days</div>
										<div className={classes.attentionNumber}>
											{needAttention.deliver.number}
										</div>
										<div className={classes.attentionMark}>
											<AiOutlineEye className={classes.icon} />{' '}
										</div>
									</div>
								)}
							</div>
						</>
					)}
				</div>
			) : (
				''
			)}
		</>
	);
};

export default Header;
