import CuratedListings from './CuratedListings';
import TopBrands from '../NotLoggedIn/TopBrands';
import TopPicks from '../NotLoggedIn/TopPicks';
import SeparatorSection from './SeparatorSection';
import UniversalCarousel from '@/components/Carousels/UniversalCarousel';
import LineSeparator from '@/components/UI/LineSeparator';
import { lazy } from 'react';

const Explore = () => {
	const exploreSection = [
		{
			categories: ['Accent Chairs'],
			link: '/collections/seating/chairs/accent-chairs',
			header: 'Accent Chairs',
			style: ['mid_century'],
			sort: { relevance: -1 },
		},
		{
			categories: ['Sofas'],
			link: '/collections/seating/sofas',
			header: 'Sofas',
			style: ['mid_century'],
			sort: { relevance: -1 },
		},
		{
			categories: ['Dining Tables'],
			link: '/collections/tables-and-desks/tables/dining-tables',
			header: 'Tables',
			sort: { relevance: -1 },
		},
		{
			categories: ['Desks'],
			link: '/collections/tables-and-desks/desks',
			header: 'Desks',
			sort: { relevance: -1 },
		},
		{
			categories: ['Storage'],
			link: '/collections/storage',
			header: 'Storage Furniture',
			style: ['mid_century'],
			sort: { relevance: -1 },
		},
		{
			categories: ['Dressers'],
			link: '/collections/storage/dressers-and-chests-of-+drawers',
			header: 'Dressers',
			style: ['mid_century'],
			sort: { relevance: -1 },
		},
		{
			categories: ['Nightstands'],
			link: '/collections/storage/nightstands',
			header: 'Nightstands',
			sort: { relevance: -1 },
		},

		{
			categories: ['Beds'],
			link: '/collections/beds',
			header: 'Sleeping',
			sort: { relevance: -1 },
		},
		{
			categories: ['Lighting'],
			link: '/collections/lighting',
			header: 'Lighting',
			sort: { relevance: -1 },
		},
		{
			categories: ['Rugs & Carpets'],
			link: '/collections/decor/rugs-and-carpets',
			header: 'Rugs & Carpets',
			sort: { relevance: -1 },
		},
		{
			categories: ['Wall Mirrors'],
			link: '/collections/decor/mirrors/wall-mirrors',
			header: 'Mirrors',
			sort: { relevance: -1 },
		},
		{
			categories: ['Decor'],
			link: '/collections/decor',
			header: 'Decor',
			sort: { relevance: -1 },
		},
	];
	return (
		<>
			<CuratedListings />
			<TopBrands />
			<TopPicks />
			<LineSeparator />
			<SeparatorSection />
			{exploreSection.map((item, idx) => {
				return (
					<span key={idx}>
						<UniversalCarousel
							categories={item.categories}
							link={item.link}
							header={item.header}
							sort={item.sort}
							style={item.style}
						/>
					</span>
				);
			})}
		</>
	);
};
export default Explore;
