'use client';
import axios from 'axios';
import { useEffect, useState } from 'react';

import Link from 'next/link';
import useLocalStorage from '@/components/Hooks/UseLocalStorage';
import MultiCarousel from '@/components/Carousels/MultiCarousel';
import classes from './TopPicks.module.css';
import { useAuthContext } from '@/context';

const RecentlyViewed = (props) => {
	const { listingID } = props;

	const { auth } = useAuthContext();

	const [showListings, setShowListings] = useState([]);

	const [recentlyViewed, setRecentlyViewed] = useLocalStorage(
		'recentlyViewed',
		[]
	);

	// Only Active & RecentlyViewed

	const getListingsHandler = async (ids) => {
		await axios
			.post(
				`${process.env.NEXT_PUBLIC_SERVER_API}/api/other/listing-with-ids`,
				{
					ids: ids.filter((id) => id !== listingID),
					status: 'active',
				}
			)
			.then((res) => {
				// Reorder listings to match the order of the ids array and filter out listing
				const orderedActiveListings = ids.map((id) =>
					res.data.find((listing) => listing._id === id)
				);

				setShowListings(orderedActiveListings);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		let ids = [];
		for (let r of recentlyViewed) {
			ids.push(r._id);
		}

		// Make sure to reverse the ids here to match the order you desire
		if (ids.length > 0) {
			getListingsHandler([...ids].reverse()); // Creates a reversed copy of ids
		}
	}, [recentlyViewed]);

	return (
		<>
			{showListings?.length > 0 && (
				<div className={classes.margin}>
					<div className={classes.headerBox}>
						<h6 className={classes.header}>Recently Viewed </h6>

						{auth.isAuthenticated && (
							<Link
								className={classes.Link}
								href={'/favorites?tab=recently-viewed'}
							>
								<h6 className={classes.seeAll}>See all</h6>
							</Link>
						)}
					</div>
					<MultiCarousel listings={showListings} />
				</div>
			)}
		</>
	);
};

export default RecentlyViewed;
