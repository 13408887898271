import Link from 'next/link';
import classes from './CuratedListings.module.css';

//syledComponents

const CuratedListings = () => {
	return (
		<>
			<div className={classes.main}>
				<Link
					className={classes.link}
					href={'/collections?sort=3&minPrice=&maxPrice=500'}
				>
					<div className={classes.container}>
						<div className={classes.imgContainer}>
							<img
								alt={'Buy and sell used chairs on Kashew'}
								src={
									'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategories/Storage/cabinets.jpg'
								}
								className={classes.img}
							/>
						</div>
						<div className={classes.footer}>
							<h3 className={classes.mainText}>DAILY DEALS</h3>
							<h4 className={classes.lowerText}>Shop Kashew to save big</h4>
						</div>
					</div>
				</Link>
				<Link
					className={classes.link}
					href={'/collections?sort=6&op=Branded&minPrice=&maxPrice=1000'}
				>
					<div className={classes.container}>
						<div className={classes.imgContainer}>
							<img
								alt={'Buy and sell used Pollock Furniture on Kashew'}
								src={
									'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategories/Seating/barcelona.jpg'
								}
								className={classes.img}
							/>
						</div>
						<div className={classes.footer}>
							<h3 className={classes.mainText}>TOP BRANDS 50% OFF +</h3>
							<h4 className={classes.lowerText}>Heavily discounted brands</h4>
						</div>
					</div>
				</Link>
				<Link className={classes.link} href={'/room/living_room'}>
					<div className={classes.container}>
						<div className={classes.imgContainer}>
							<img
								alt={'Buy and sell used Poulsen Furniture on Kashew'}
								src={
									'https://ik.imagekit.io/kuj9zqlrx0or/Static/FurnitureCategories/Seating/sectional.jpg'
								}
								className={classes.img}
							/>
						</div>
						<div className={classes.footer}>
							<h3 className={classes.mainText}>LIVING ROOM FURNITURE</h3>
							<h4 className={classes.lowerText}>Old out, preloved in</h4>
						</div>
					</div>
				</Link>
			</div>
		</>
	);
};

export default CuratedListings;
