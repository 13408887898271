'use client';
import { useEffect, useState } from 'react';
import classes from './MultiCarousel.module.css';

const PlaceHolder = () => {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		// Check if the window is defined (runs only on the client side)
		if (typeof window !== 'undefined') {
			const handleResize = () => {
				setIsMobile(window.innerWidth < 601);
			};

			// Run once on mount
			handleResize();

			// Add event listener to handle window resize
			window.addEventListener('resize', handleResize);

			// Cleanup the event listener on component unmount
			return () => {
				window.removeEventListener('resize', handleResize);
			};
		}
	}, []);

	const arrMobile = [1, 2];
	const arrDesktop = [1, 2, 3, 4, 5];

	return (
		<>
			<section className={classes.gap}>
				{isMobile
					? arrMobile.map((i) => (
							<div className={`${classes.listingCard} `} key={i}>
								<div className={classes.carouselPlaceholder}></div>
								<div className={classes.cardBodyPlaceholder}>
									<div className={classes.placeholderBadge}></div>
									<div className={classes.placeholderBadge}></div>
								</div>
							</div>
					  ))
					: arrDesktop.map((i) => (
							<div className={`${classes.listingCard} `} key={i}>
								<div className={classes.carouselPlaceholder}></div>
								<div className={classes.cardBodyPlaceholder}>
									<div className={classes.placeholderBadge}></div>
									<div className={classes.placeholderBadge}></div>
								</div>
							</div>
					  ))}
			</section>
		</>
	);
};

export default PlaceHolder;
