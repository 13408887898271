// AuthStatusUpdater.js (Client Component)
'use client';

import { useEffect } from 'react';
import { useAuthContext } from '@/context'; // Assuming you're using this context
import { useRouter } from 'next/navigation';

const AuthStatusUpdater = () => {
	const { auth } = useAuthContext();
	const router = useRouter();

	useEffect(() => {
		// Whenever authentication status changes, refresh the page to load server content
		router.refresh(); // This will trigger Next.js to re-fetch the page, server-side
	}, [auth, router]);

	return null;
};

export default AuthStatusUpdater;
