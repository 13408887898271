'use client';
import { useState, useEffect } from 'react';
import PlaceHolderListingNew from './PlaceHolderListingNew';
import classes from './MultiCarousel.module.css';
import { motion } from 'framer-motion';
import { GrNext, GrPrevious } from 'react-icons/gr';
import Listing from './Listing';

const MultiCarousel = ({ listings }) => {
	const [offset, setOffset] = useState(0);
	const [itemsPerSlide, setItemsPerSlide] = useState(5);
	const [itemWidth, setItemWidth] = useState(228);

	const totalItems = listings.filter((listing) => listing !== undefined).length;
	const maxOffset = -(totalItems - itemsPerSlide) * itemWidth;

	useEffect(() => {
		// Update itemsPerSlide and itemWidth based on window size
		const updateDimensions = () => {
			if (global?.window && window.innerWidth > 600) {
				setItemsPerSlide(5);
				setItemWidth(1140 * 0.2);
			} else {
				setItemsPerSlide(2);
				setItemWidth(global?.window && window.innerWidth * 0.5 - 12);
			}
		};

		// Initial call to set dimensions
		updateDimensions();

		// Event listener for window resize
		global?.window && window.addEventListener('resize', updateDimensions);

		// Clean up the event listener
		return () => {
			global?.window && window.removeEventListener('resize', updateDimensions);
		};
	}, []);

	const nextHandler = () => {
		setOffset((prevOffset) => {
			const newOffset = prevOffset - itemWidth * itemsPerSlide;
			return Math.max(newOffset, maxOffset);
		});
	};

	const prevHandler = () => {
		setOffset((prevOffset) => {
			const newOffset = prevOffset + itemWidth * itemsPerSlide;
			return Math.min(newOffset, 0);
		});
	};

	const dragHandlerEnd = (event, info) => {
		const dragDistance = info.offset.x;

		if (dragDistance > 100) {
			// Dragged to the right, move to previous slide
			prevHandler();
		} else if (dragDistance < -100) {
			// Dragged to the left, move to next slide
			nextHandler();
		}
	};

	return (
		<section className={classes.carouselContainer}>
			{/* Render previous button if not at the beginning */}
			{offset !== 0 && (
				<div className={classes.previous} onClick={prevHandler}>
					<GrPrevious className={classes.arrow} />
				</div>
			)}
			<motion.div
				className={classes.carouselMask}
				drag="x"
				dragElastic={1}
				dragConstraints={{ left: maxOffset, right: 0 }}
				animate={{ x: offset }}
				transition={{ type: 'spring', stiffness: 200, damping: 30 }}
				onDragEnd={dragHandlerEnd}
			>
				{/* Render listings or placeholder if listings are empty */}
				{listings && listings.length > 0 ? (
					listings
						.filter((listing) => listing !== undefined)
						.map((listing, idx) => (
							<div className={classes.listing} key={idx}>
								<Listing listing={listing} idx={idx} />
							</div>
						))
				) : (
					<div style={{ height: '290px' }}>
						<PlaceHolderListingNew />
					</div>
				)}
			</motion.div>

			{/* Render next button if not at the end */}
			{offset > maxOffset && (
				<div className={classes.next} onClick={nextHandler}>
					<GrNext className={classes.arrow} />
				</div>
			)}
		</section>
	);
};

export default MultiCarousel;
