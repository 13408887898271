"use client";

import { motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { GrNext, GrPrevious } from "react-icons/gr";
import brandsFile from "../../../utils/brands.js";
import classes from "./TopBrands.module.css";

const TopBrands = () => {
  const [sortedBrands, setSortedBrands] = useState([]);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    setBrands(brandsFile); // Just set the brandsFile directly without fetching
  }, []);

  const favoriteBrands = [
    "West Elm",
    "Herman Miller",
    "Pottery Barn",
    "Room & Board",
    "Ethan Allen",
    "Design Within Reach",
    "Restoration Hardware",
    "Thomasville",
    "CB2",
    "de Sede",
    "Oliver Space",
    "Article",
    "Poppin",
    "Pier 1 Imports",
    "Knoll",
    "Living Spaces",
    "Blu Dot",
    "Autonomous",
    "Ashley",
    "Roche Bobois",
    "Zuo Modern",
    "Ikea",
  ];

  const [offset, setOffset] = useState(0);
  const [itemsPerSlide, setItemsPerSlide] = useState(5);
  const [itemWidth, setItemWidth] = useState(228);

  const totalItems = sortedBrands.length;
  const maxOffset = -(totalItems - itemsPerSlide) * itemWidth;

  useEffect(() => {
    // Step 1: Filter and sort brands
    let filteredAndSortedBrands = brands.filter((b) =>
      favoriteBrands.includes(b.brand)
    );

    filteredAndSortedBrands.sort(
      (a, b) =>
        favoriteBrands.indexOf(a.brand) - favoriteBrands.indexOf(b.brand)
    );

    // Step 2: Transform filtered and sorted brands into pairs
    let pairedBrands = [];
    for (let i = 0; i < filteredAndSortedBrands.length; i += 2) {
      let pair = filteredAndSortedBrands.slice(i, i + 2);
      pairedBrands.push(pair);
    }

    // Step 3: Update state
    setSortedBrands(pairedBrands);
  }, [brands]);

  useEffect(() => {
    // Update itemsPerSlide and itemWidth based on window size
    const updateDimensions = () => {
      if (global.window && window.innerWidth > 600) {
        setItemsPerSlide(5);
        setItemWidth(1140 * 0.2);
      } else {
        setItemsPerSlide(2);
        setItemWidth(global?.window && window.innerWidth * 0.5 - 12);
      }
    };

    // Initial call to set dimensions
    updateDimensions();

    // Event listener for window resize
    global.window && window.addEventListener("resize", updateDimensions);

    // Clean up the event listener
    return () => {
      global.window && window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const nextHandler = () => {
    setOffset((prevOffset) => {
      const newOffset = prevOffset - itemWidth * itemsPerSlide;
      return Math.max(newOffset, maxOffset);
    });
  };

  const prevHandler = () => {
    setOffset((prevOffset) => {
      const newOffset = prevOffset + itemWidth * itemsPerSlide;
      return Math.min(newOffset, 0);
    });
  };

  const dragHandlerEnd = (event, info) => {
    const dragDistance = info.offset.x;
    if (dragDistance > 100) {
      prevHandler();
    } else if (dragDistance < -100) {
      nextHandler();
    }
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.headerBox}>
        <h6 className={classes.header}>Top Selling Brands This Week</h6>
        <Link className={classes.Link} href={"/brand"}>
          <h6 className={classes.seeAll}>See all</h6>
        </Link>
      </div>

      <div className={classes.carouselContainer}>
        {offset !== 0 ? (
          <div className={classes.previous} onClick={prevHandler}>
            <GrPrevious className={classes.arrow} />
          </div>
        ) : (
          ""
        )}
        <motion.div
          className={classes.carouselMask}
          drag="x"
          dragElastic={1}
          dragConstraints={{ left: maxOffset, right: 0 }}
          animate={{ x: offset }}
          transition={{ type: "spring", stiffness: 200, damping: 30 }}
          onDragEnd={dragHandlerEnd}
        >
          {sortedBrands
            .filter((brand) => brand.length > 0)
            .map((brand, idx) => (
              <div className={classes.container} key={idx}>
                {brand &&
                  brand !== "undefined" &&
                  brand.map((b, idx) => (
                    <Link
                      className={classes.imgContainer}
                      key={idx}
                      href={`/pages/${
                        b.brand === "Ashley"
                          ? "ashley-furniture"
                          : b.brand
                              .replace(/ /g, "-")
                              .replace(/&/g, "and")
                              .toLowerCase()
                      }`}
                    >
                      <Image
                        alt={b.brand}
                        className={classes.img}
                        width={212}
                        height={150}
                        loading="lazy"
                        src={`${b.link}`}
                      />
                    </Link>
                  ))}
              </div>
            ))}
        </motion.div>
        {offset > maxOffset ? (
          <div className={classes.next} onClick={nextHandler}>
            <GrNext className={classes.arrow} />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TopBrands;
