import UniversalCarousel from '@/components/Carousels/UniversalCarousel';

const NewArrivals = () => {
	const exploreSection = [
		{
			categories: ['Accent Chairs'],
			link: '/collections/seating/chairs/accent-chairs',
			header: 'Accent Chairs',
			style: ['mid_century'],
			sort: { date: -1 },
		},
		{
			categories: ['Sofas'],
			link: '/collections/seating/sofas',
			header: 'Sofas',
			style: ['mid_century'],
			sort: { date: -1 },
		},
		{
			categories: ['Dining Tables'],
			link: '/collections/tables-and-desks/tables/dining-tables',
			header: 'Tables',
			sort: { date: -1 },
		},
		{
			categories: ['Desks'],
			link: '/collections/tables-and-desks/desks',
			header: 'Desks',
			sort: { date: -1 },
		},
		{
			categories: ['Storage'],
			link: '/collections/storage',
			header: 'Storage Furniture',
			style: ['mid_century'],
			sort: { date: -1 },
		},
		{
			categories: ['Dressers'],
			link: '/collections/storage/dressers-and-chests-of-drawers',
			header: 'Dressers',
			style: ['mid_century'],
			sort: { date: -1 },
		},
		{
			categories: ['Nightstands'],
			link: '/collections/storage/nightstands',
			header: 'Nightstands',
			sort: { date: -1 },
		},

		{
			categories: ['Beds'],
			link: '/collections/beds',
			header: 'Sleeping',
			sort: { date: -1 },
		},
		{
			categories: ['Lighting'],
			link: '/collections/lighting',
			header: 'Lighting',
			sort: { date: -1 },
		},
		{
			categories: ['Rugs & Carpets'],
			link: '/collections/decor/rugs-and-carpets',
			header: 'Rugs & Carpets',
			sort: { date: -1 },
		},
		{
			categories: ['Wall Mirrors'],
			link: '/collections/decor/mirrors/wall-mirrors',
			header: 'Mirrors',
			sort: { date: -1 },
		},
		{
			categories: ['Decor'],
			link: '/collections/decor',
			header: 'Decor',
			sort: { date: -1 },
		},
	];
	return (
		<>
			{exploreSection.map((item, idx) => {
				return (
					<span key={idx}>
						<UniversalCarousel
							categories={item.categories}
							link={item.link}
							header={item.header}
							sort={item.sort}
							style={item.style}
						/>
					</span>
				);
			})}
		</>
	);
};
export default NewArrivals;
