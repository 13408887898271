import Link from "next/link";
import classes from "./Blogs.module.css";
import Image from "next/image";

const BlogSection = (props) => {
  const { article, idx } = props;

  return (
    <Link
      key={idx}
      className={idx === 0 || idx % 4 === 0 ? classes.link1 : classes.link}
      href={`/blog/${article._id}`}
    >
      {idx === 0 || idx % 4 === 0 ? (
        <Image
          width={444}
          height={403}
          src={`${article.picture}?tr=w-650`}
          alt={article.title}
          className={classes.bigPic}
          referrerPolicy="no-referrer"
        />
      ) : (
        <Image
          width={222}
          height={230}
          src={`${article.picture}?tr=w-350,h-350`}
          alt={article.title}
          className={classes.picture}
          referrerPolicy="no-referrer"
        />
      )}

      <div
        className={
          idx === 0 || idx % 4 === 0
            ? classes.descriptionOnScreen
            : classes.description
        }
      >
        <h2 className={classes.title}>{article.title}</h2>

        <div className={classes.infoSection}>
          <div className={classes.tags}>
            {article.tags?.length > 0 &&
              article.tags.map((i, idx) => (
                <h3 key={idx} className={classes.tag}>
                  #{i}
                </h3>
              ))}
          </div>

          <h3 className={classes.authorName}>
            Written by {article.author.firstName}{" "}
            {article.author.lastName !== "undefined"
              ? article.author.lastName
              : ""}
          </h3>
        </div>
      </div>
    </Link>
  );
};
export default BlogSection;
