'use client';
import Cookies from 'js-cookie';
import { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useAuthContext } from '.';

// Create the cart context with default values
const SalesContext = createContext({
	openSales: {
		loading: false,
		loaded: false,
		orders: [],
	},
	closedSales: {
		loading: false,
		loaded: false,
		orders: [],
	},
	openOffers: {
		loading: false,
		loaded: false,
		orders: [],
	},
	closedOffers: {
		loading: false,
		loaded: false,
		orders: [],
	},

	LOAD_SALES: () => {},
});

// Create a provider component for the cart context
export const SalesProvider = ({ children }) => {
	const { auth } = useAuthContext();

	const [sales, setSales] = useState({
		openSales: {
			loading: false,
			loaded: false,
			orders: [],
		},
		closedSales: {
			loading: false,
			loaded: false,
			orders: [],
		},
		openOffers: {
			loading: false,
			loaded: false,
			orders: [],
		},
		closedOffers: {
			loading: false,
			loaded: false,
			orders: [],
		},
	});

	const LOAD_SALES = async ({ type, reload }) => {
		if (sales[type]?.loaded && !reload) {
			return;
		}

		setSales((prevState) => ({
			...prevState,
			[type]: { ...prevState[type], loading: true },
		}));

		// return;
		await axios
			.post(
				`${process.env.NEXT_PUBLIC_SERVER_API}/api/chat/sales`,
				{
					type,
				},
				{
					headers: {
						'x-auth-token': Cookies.get('token'),
					},
				}
			)
			.then(async (res) => {
				setSales((prevState) => ({
					...prevState,
					[type]: { loading: false, loaded: true, orders: res.data },
				}));
			})
			.catch(async (error) => {
				console.log(error);
				setSales((prevState) => ({
					...prevState,
					[type]: { loading: false, loaded: true, orders: [] },
				}));
			});
	};

	useEffect(() => {
		if (auth.isAuthenticated) {
			LOAD_SALES({ type: 'openSales', reload: true });
			LOAD_SALES({ type: 'openOffers', reload: true });
		}
	}, [auth]);

	return (
		<SalesContext.Provider
			value={{
				sales,
				LOAD_SALES,
			}}
		>
			{children}
		</SalesContext.Provider>
	);
};

// Custom hook to use the cart context
export const useSalesContext = () => {
	return useContext(SalesContext);
};
