'use client';
import { useState, useMemo } from 'react';
import classes from './Recommended.module.css';
import Listing from '@/components/Carousels/Listing';

import algoliasearch from 'algoliasearch';
const client = algoliasearch(
	process.env.NEXT_PUBLIC_ALGOLIA_APPID,
	process.env.NEXT_PUBLIC_ALGOLIA_API_KEY
);

const Recommended = (props) => {
	const { recentlyViewed, long } = props;

	const [displayListings, setDisplayListings] = useState([]);
	const [showListings, setShowListings] = useState([]);

	const getListingsHandler = async (categoryList, styleList) => {
		const index = client.initIndex('kashew_listings');
		await index
			.search('', {
				hitsPerPage: 50,
				clickAnalytics: true,
				page: 0,
				filters: `status:active${
					categoryList?.length > 0
						? ` AND (${categoryList.map((category, idx) =>
								idx === 0
									? `newSchema.categories:"${category}" OR newSchema.subCategories:"${category}" OR newSchema.types:"${category}"`
									: ` OR newSchema.categories:"${category}" OR newSchema.subCategories:"${category}" OR newSchema.types:"${category}"`
						  )})`
						: ''
				}`.replace(/,/g, ''),
			})
			.then(async (res) => {
				setDisplayListings(res.hits);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useMemo(() => {
		let categoryList = [];

		let styleList = [];

		for (let r of recentlyViewed.slice(0, 3)) {
			if (r?.newSchema?.types[0]) {
				categoryList.push(r.newSchema.types[0]);
			} else if (r?.newSchema?.subCategories[0]) {
				categoryList.push(r.newSchema.subCategories[0]);
			}
			if (r?.velouAttributes?.styles) {
				styleList.push(r.velouAttributes?.styles);
			}
		}

		getListingsHandler(categoryList, styleList);
	}, [recentlyViewed]);

	//RecentlyViewed

	useMemo(() => {
		let filteredListings = [...new Set(displayListings)];

		if (!long) {
			setShowListings(
				global?.window && window.innerWidth > 1300
					? filteredListings.slice(0, 8)
					: global?.window &&
					  window.innerWidth < 1300 &&
					  window.innerWidth > 990
					? filteredListings.slice(0, 6)
					: filteredListings.slice(0, 8)
			);
		}
		if (long) {
			setShowListings(filteredListings.slice(0, 48));
		}
	}, [displayListings]);

	return (
		<>
			{showListings.length > 0 && (
				<div className={classes.main}>
					<div className={classes.listingsGrid}>
						{showListings.map((listing, idx) => (
							<li className={classes.listing} key={listing._id}>
								<Listing listing={listing} idx={idx} />
							</li>
						))}
					</div>
				</div>
			)}
		</>
	);
};

export default Recommended;
