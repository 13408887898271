'use client';
import { useEffect, useState } from 'react';
import classes from './TopPicks.module.css';
import axios from 'axios';
import Link from 'next/link';
import MultiCarousel from '@/components/Carousels/MultiCarouselAlsoLike';
import PlaceHolder from '@/components/Carousels/PlaceHolder';

const TopPicks = () => {
	const [mostViewedListings, setMostViewedListings] = useState([]);
	const [loading, setLoading] = useState(false);

	const getListingsHandler = async () => {
		setLoading(true);
		await axios
			.post(`${process.env.NEXT_PUBLIC_SERVER_API}/api/other/top-picks`, {
				limit: 15,
			})
			.then((res) => {
				setMostViewedListings(res.data);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		getListingsHandler();
	}, []);

	return (
		<>
			<div className={classes.margin}>
				<div className={classes.headerBox}>
					<h6 className={classes.header}>Just In & Best Deals</h6>
					<Link className={classes.Link} href={'/selections/top-picks'}>
						{' '}
						<h6 className={classes.seeAll}>See all</h6>{' '}
					</Link>
				</div>
				{mostViewedListings.length < 1 || loading ? (
					<PlaceHolder />
				) : (
					<MultiCarousel listings={mostViewedListings} />
				)}
			</div>
		</>
	);
};

export default TopPicks;
