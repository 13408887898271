'use client';
import Link from 'next/link';
import classes from './SeparatorSection.module.css';
import { useEffect, useState } from 'react';

const SeparatorSection = (props) => {
	const [innerWidth, setInnerWidth] = useState();
	useEffect(() => {
		setInnerWidth(window.innerWidth);
	}, []);
	return (
		<>
			<div className={classes.main}>
				<div className={classes.container}>
					<div className={classes.text}>Explore more unique finds.</div>
					<div className={classes.imgContainer}>
						{/* <div className={classes.catchMe}>Catch us!</div> */}
						<Link href={'/collections/seating/armchairs'}>
							<img
								className={
									innerWidth > 600 ? classes.img1 : classes.img1Smaller
								}
								alt={'Buy and sell used arm chairs on Kashew'}
								src={
									'https://ik.imagekit.io/kuj9zqlrx0or/Static/armChair.png?tr=w-100px,h-100px'
								}
							/>
						</Link>
						<Link href={'/collections/seating/sofas'}>
							<img
								className={
									innerWidth > 600 ? classes.img2 : classes.img2Smaller
								}
								src={
									'https://ik.imagekit.io/kuj9zqlrx0or/Static/orangeCouch.png?tr=w-120px,h-120px'
								}
								alt={'Buy and sell used couches on Kashew'}
							/>
						</Link>
						<Link href={'/collections/seating/chairs'}>
							<img
								className={
									innerWidth > 600 ? classes.img3 : classes.img3Smaller
								}
								alt={'Buy and sell used vitra chairs on Kashew'}
								src={
									'https://ik.imagekit.io/kuj9zqlrx0or/Static/vitra.png?tr=w-90px,h-90px'
								}
							/>
						</Link>
						<Link href={'/collections/tables-and-desks'}>
							<img
								className={
									innerWidth > 600 ? classes.img4 : classes.img4Smaller
								}
								alt={'Buy and sell used Knoll furniture on Kashew'}
								src={
									'https://ik.imagekit.io/kuj9zqlrx0or/Static/knoll.png?tr=w-120px,h-120px'
								}
							/>
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};

export default SeparatorSection;
