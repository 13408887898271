import classes from './LineSeparator.module.css';

const LineSeparator = (props) => {
	return (
		<div className={`${classes.separator} ${props.className}`}>
			<div className={classes.line}></div>
		</div>
	);
};

export default LineSeparator;
