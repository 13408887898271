"use client";

import { useRouter } from "next/navigation";

const ClickySpan = (props) => {
  const { clickUrl, className } = props;
  const router = useRouter();

  return (
    <>
      <span className={className} onClick={() => router.push(clickUrl)}>
        {props.children}
      </span>
    </>
  );
};
export default ClickySpan;
